import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import GridOnIcon from '@mui/icons-material/GridOn';
import LabelIcon from '@mui/icons-material/Label';

function DownloadPdfIcon(){
    return (
        <Tooltip title="Download as PDF">
                <PictureAsPdfIcon sx={{color: 'rgba(37,26,58,0.8)'}}/>
        </Tooltip>
    )
}
function DownloadCsvIcon(){
    return (
        <Tooltip title="Download as CSV">
                <GridOnIcon sx={{color: 'rgba(37,26,58,0.8)'}}/>
        </Tooltip>
    )
}

function DownloadLabelIcon(){
    return (
        <Tooltip title="Download Labels">
                <LabelIcon sx={{color: 'rgba(37,26,58,0.8)'}}/>
        </Tooltip>
    )
}

export { DownloadPdfIcon, DownloadCsvIcon, DownloadLabelIcon };