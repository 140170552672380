import * as React from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_2"
        width={400}
        height={80}
        data-name="Layer 2"
        viewBox="0 0 500 100"
        {...props}
    >
        <defs>
            <style>
                {
                    ".cls-3{isolation:isolate}.cls-13,.cls-14,.cls-15,.cls-16,.cls-17{stroke:#635b5c}.cls-13,.cls-14,.cls-16,.cls-18,.cls-19{fill:none}.cls-13,.cls-14,.cls-15,.cls-16,.cls-17,.cls-18,.cls-19,.cls-21{stroke-miterlimit:10}.cls-13,.cls-21{stroke-width:1.71px}.cls-22{fill:#fff}.cls-18{stroke:#e36f53}.cls-15,.cls-16,.cls-17,.cls-18,.cls-19{stroke-width:.74px}.cls-19,.cls-21{stroke:#812111}.cls-21{fill:#fa714c}.cls-22{stroke-width:0}.cls-17{fill:#e5e5e5}.cls-14{stroke-width:.37px}.cls-15{fill:#95979a}"
                }
            </style>
        </defs>
        <text
            className="cls-3"
            style={{
                fontFamily: "ArialMT,Arial",
                fontSize: "74.99px",
                letterSpacing: ".02em",
                fill: "#000",
                isolation: "isolate",
            }}
            transform="matrix(.98 0 0 1 16 72.28)"
        >
            <tspan x={0} y={0}>
                {"Zippy"}
            </tspan>
        </text>
        <text
            className="cls-3"
            style={{
                strokeWidth: "5.54px",
                stroke: "#812111",
                fontFamily: "ArialMT,Arial",
                fontSize: "74.99px",
                letterSpacing: ".02em",
                fill: "none",
                isolation: "isolate",
            }}
            transform="matrix(.98 0 0 1 16 72.28)"
        >
            <tspan x={0} y={0}>
                {"Zippy"}
            </tspan>
        </text>
        <text
            className="cls-3"
            style={{
                stroke: "#9c5044",
                strokeWidth: "2.22px",
                fontFamily: "ArialMT,Arial",
                fontSize: "74.99px",
                letterSpacing: ".02em",
                fill: "none",
                isolation: "isolate",
            }}
            transform="matrix(.98 0 0 1 16 72.28)"
        >
            <tspan x={0} y={0}>
                {"Zippy"}
            </tspan>
        </text>
        <text
            className="cls-3"
            style={{
                fill: "#fa714c",
                fontFamily: "ArialMT,Arial",
                fontSize: "74.99px",
                letterSpacing: ".02em",
                isolation: "isolate",
            }}
            transform="matrix(.98 0 0 1 16 72.28)"
        >
            <tspan x={0} y={0}>
                {"Zippy"}
            </tspan>
        </text>
        <text
            className="cls-3"
            style={{
                fill: "#000",
                fontFamily: "Arial-BoldMT,Arial",
                fontSize: "83.78px",
                fontWeight: 700,
                letterSpacing: ".02em",
                isolation: "isolate",
            }}
            transform="matrix(.98 0 0 1 210.81 77.69)"
        >
            <tspan x={0} y={0}>
                {"Docs"}
            </tspan>
        </text>
        <text
            className="cls-3"
            style={{
                fontFamily: "Arial-BoldMT,Arial",
                fontSize: "83.78px",
                fontWeight: 700,
                letterSpacing: ".02em",
                fill: "none",
                stroke: "#635b5c",
                isolation: "isolate",
                strokeWidth: "9.57px",
            }}
            transform="matrix(.98 0 0 1 210.81 77.69)"
        >
            <tspan x={0} y={0}>
                {"Docs"}
            </tspan>
        </text>
        <text
            className="cls-3"
            style={{
                stroke: "#adadad",
                strokeWidth: "6.37px",
                fontFamily: "Arial-BoldMT,Arial",
                fontSize: "83.78px",
                fontWeight: 700,
                letterSpacing: ".02em",
                fill: "none",
                isolation: "isolate",
            }}
            transform="matrix(.98 0 0 1 210.81 77.69)"
        >
            <tspan x={0} y={0}>
                {"Docs"}
            </tspan>
        </text>
        <text
            className="cls-3"
            style={{
                fill: "#fff",
                fontFamily: "Arial-BoldMT,Arial",
                fontSize: "83.78px",
                fontWeight: 700,
                letterSpacing: ".02em",
                isolation: "isolate",
            }}
            transform="matrix(.98 0 0 1 210.81 77.69)"
        >
            <tspan x={0} y={0}>
                {"Docs"}
            </tspan>
        </text>
        <path
            d="M470.67 12.05v50.36h-12v13.34h-33.78v-63.7h45.78z"
            className="cls-21"
        />
        <path d="M458.71 62.52h11.96l-11.96 13.42V62.52z" className="cls-21" />
        <path
            d="M424.64 12.06v63.88"
            style={{
                stroke: "#812111",
                strokeWidth: "1.71px",
                strokeMiterlimit: 10,
                fill: "none",
            }}
        />
        <path
            d="M424.99 12.05h-.1.1zM470.91 62.41h-.24v-.29l.24.29zM470.67 62.41v.11l-.11-.11h.11zM458.71 75.94h-34.07 34.07ZM470.67 62.52l-11.96 13.42 11.96-13.42z"
            className="cls-21"
        />
        <path
            d="M433.68 29.61H469M434.15 35.37h35.19M433.92 41.14h35.44M433.68 47.85h35.79M433.68 54.41H469M433.68 61.27h25.51M433.45 22.96h24.99"
            className="cls-19"
        />
        <path
            d="M487.26 39.56h-12.6V26.15l12.6 13.41z"
            style={{
                fill: "#e5e5e5",
                strokeWidth: 0,
            }}
        />
        <path d="M438.74 90.03V26.15" className="cls-13" />
        <path d="M439.01 26.35h35.62V40.4h-35.62z" className="cls-22" />
        <path d="M487.52 39.69h-48.51v50.46" className="cls-22" />
        <path
            d="M438.74 90.03h48.52V39.56"
            style={{
                fill: "#fff",
                strokeWidth: "1.71px",
                strokeMiterlimit: 10,
                stroke: "#635b5c",
            }}
        />
        <path
            d="M474.66 26.14h-35.92 35.92ZM487.26 39.56h-12.6 12.6ZM474.66 26.15v13.43-13.43ZM474.66 26.14v.01l12.6 13.41"
            className="cls-13"
        />
        <path
            d="M454.74 49.13h19.11M454.99 54.89h19.04M454.86 60.64h19.19M454.74 67.36h19.37M454.74 73.92h19.11M454.74 80.78h13.81M454.62 42.47h13.52"
            className="cls-18"
        />
        <path d="M443.95 26.02h9.96v5.61h-9.96z" className="cls-15" />
        <path
            d="M443.95 31.45h9.96v58.11h-9.96z"
            style={{
                fill: "#adadad",
                strokeWidth: ".74px",
                strokeMiterlimit: 10,
                stroke: "#635b5c",
            }}
        />
        <path d="M446.55 37.06h5.29v49.13h-5.29z" className="cls-15" />
        <path d="M449.25 31.67v52.04" className="cls-14" />
        <path d="m446.59 83.67 5.28.08" className="cls-16" />
        <path
            d="m446.6 80.12 5.29.08M446.57 76.57l5.27.08M446.57 73.02l5.27.08M446.68 69.47l5.27.08M446.59 65.92l5.28.07M446.57 62.37l5.27.07M446.68 58.82l5.27.07M446.68 55.27l5.27.07M446.57 51.72l5.27.07"
            className="cls-14"
        />
        <path
            d="m446.57 48.17 5.27.07M446.6 44.62l5.29.07M446.57 41.36l5.27.08M446.57 38.11l5.27.07"
            className="cls-16"
        />
        <path
            d="M449.19 36.49c-2.4 0-2.4 9.52-2.4 13.57s1.08 3.69 2.4 3.69 2.4.38 2.4-3.69.47-13.57-2.4-13.57Zm.25 16.19h-.8c-.47 0-.86-.63-.86-1.43s.38-1.43.86-1.43h.8c.47 0 .86.64.86 1.43s-.38 1.43-.86 1.43Z"
            className="cls-17"
        />
        <path d="M446.6 36.93h5.25v1.18h-5.25z" className="cls-17" />
        <rect
            width={2.02}
            height={6.67}
            x={448.19}
            y={35.04}
            className="cls-17"
            rx={0.59}
            ry={0.59}
        />
        <path d="M446.69 83.56h5.25v2.51h-5.25z" className="cls-17" />
        <path
            d="M389.67 47.68h-.02"
            style={{
                strokeWidth: ".81px",
                strokeMiterlimit: 10,
                fill: "none",
                stroke: "#635b5c",
            }}
        />
    </svg>
)
export default SvgComponent
