import HandymanIcon from "@mui/icons-material/Handyman";
import {Card} from "@mui/material";

const Account = () => {
    return (
        <Card sx={{alignContent: 'center'}}>
            <h1 style={{color: '#19666c'}}>Account</h1>
            <p>Nothing here yet, check back soon!</p>
            <HandymanIcon fontSize="large" sx={{color: '#ee5840'}}/>
        </Card>
    )
}

export default Account;