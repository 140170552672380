import {createTheme} from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#100000'
        }
    },
    typography: {
        fontFamily: [
            'Titillium Web',
            'sans-serif',
        ].join(','),
    },
    components: {
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: '#19656b',
                        '&.Mui-checked': {
                            color: '#19656b'
                        },
                    },
                },
            },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(248,253,255,0.8)',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: 25,
                    width: '50vh',
                    alignContent: "center",
                    marginTop: 40,
                },
            },
        },
        MuiDatePicker: {
            styleOverrides: {
                root: {
                    margin: "dense"
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                        color: "#19656b",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 25,
                        color: '#130206',
                        backgroundColor: "rgb(232,235,239)",
                        size: "small",
                    },
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(8,8,35)',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#380202FF',
                    fontWeight: 505,
                    textTransform: "none",
                    borderRadius: '20px',
                    maxWidth: '150px',
                    marginTop: '20px',
                    marginBottom: '20px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    backgroundColor: '#F05941',
                    '&:hover': {
                        backgroundColor: '#BE3144',
                    },
                    "&.Mui-disabled": {
                        "color": "rgba(0, 0, 0, 0.26)",
                        "backgroundColor": '#BE3144'
                    }
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        textAlign: "center",
                        borderRadius: 25,
                        backgroundColor: "rgba(254,254,254,0)",
                        textDecoration: 'none',
                        color: '#862340',
                        border: 'none',
                        fontSize: '1.4rem',
                        '&:focus': {
                            outline: 'none'
                        },
                    },
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    width: '300px',
                    backgroundColor: 'rgba(233,241,243,0.52)',
                },
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    margin: 'auto', // Center the CircularProgress horizontally
                    color: "#862340"
                },
            },
            defaultProps: {
                size: 100,
                thickness: 3,
            },
        },
    },
});