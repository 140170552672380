//IMPORTS---------------------------------------------------------------------------------------------------------
import React, {useState, useEffect, useRef} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { DownloadPdfIcon, DownloadCsvIcon, DownloadLabelIcon } from '../Components/ActionIcons';
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "../API/axios";
import CardContent from "@mui/material/CardContent";
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import {useAuth} from "../Context/AuthProvider";

//DECLARATIONS---------------------------------------------------------------------------------------------------------
const Dashboard = () => {
    const [tableData, setTableData] = useState([]);
    const [downloadStatus, setDownloadStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(true);
    const userRef = useRef();
    const errRef = useRef();
    const {user, logoutUser} = useAuth()


    useEffect(() => {
        userRef.current?.focus();
    }, []);

    useEffect(() => {
        // Make a GET request using Axios
        axios.get('/Dashboard', {
            headers: { Authorization: 'Bearer '.concat(localStorage.getItem('placeholderToken')) }
        })
            .then(response => {
                // Handle successful response
                setTableData(response.data);
                setTableLoading(false);
            })
            .catch(error => {
                // Handle error
                console.error('Error fetching data:', error);
                setTableLoading(false);
            });
    }, []);

    console.log(tableData);

    /*const updateErrorHandler = (error) => {
        console.log('Error!', error);
    }
    */

    const DownloadProjectFile = async (rowId, fileType) => {
        setDownloadStatus("Pending");
        axios({
            url: '/GetProjectFile',
            method: 'GET',
            headers: { Authorization: 'Bearer '.concat(localStorage.getItem('placeholderToken')) },
            responseType: 'blob', // important
            params: {file_type: fileType, project_id: rowId},
            file_type: 'csv',
            project_id: 100
        }).then((response) => {
            if (response?.status === 200) {
                setDownloadStatus("Complete");
                // Determine the file extension based on the fileType
                const fileExtension = fileType === 'csv' ? 'csv' : 'pdf';
                // Create a temporary anchor element
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(response.data);
                downloadLink.setAttribute('download', `project_${rowId}.${fileExtension}`);
                // Simulate click on the anchor to initiate download
                downloadLink.click();
                // Remove the anchor element from the DOM
                setTimeout(() => {
                    window.URL.revokeObjectURL(downloadLink.href);
                    downloadLink.remove();
                }, 100);
            } else {
                setDownloadStatus('An error has occurred');
                console.log('An error has occurred');
            }
        }).catch((error) => {
            if (error.response?.status === 404) {
                setDownloadStatus('File not ready, try again in a few minutes');
                console.log('The requested resource does not exist or has been deleted');
            } else if (error.response?.status === 401) {
                logoutUser();
            } else {
                setDownloadStatus('An error has occurred');
                console.log('An error has occurred');
            }
            errRef.current?.focus();
        });
    };

    const SkeletonRows = (params) => (
        tableLoading ? (
            <Skeleton sx={{width: '200px', borderRadius: 10}} />
        ) : (
            params.value
        )
    );

    const Columns = [
        { field: 'id', headerName: 'ID', headerAlign: 'center', align: 'center', width: 80, renderCell: SkeletonRows },
        { field: 'project_name', headerName: 'Project Name', headerAlign: 'center', align: 'center', width: 200,
            //need to change editable back to true when ready to re-implement project updating
            editable: false, renderCell: SkeletonRows },
        { field: 'address_count', headerName: 'Address Count', headerAlign: 'center', align: 'center', width: 160, renderCell: SkeletonRows },
        { field: 'date_processed', headerName: 'Date Processed', type: 'string', headerAlign: 'center', align: 'center', width: 160,
            renderCell: SkeletonRows},
        { field: 'status', headerName: 'Status', type: 'singleSelect', valueOptions: ['Not Started', 'In Progress', 'Complete'],
            //need to change editable back to true when ready to re-implement project updating
            editable: false, headerAlign: 'center', align: 'center', width: 160, renderCell: SkeletonRows},
        { field: 'attachments', headerName: 'Attachments',
            renderCell: (params)=> (
                tableLoading ? ( <Skeleton sx={{width: '200px', borderRadius: 10}} />) : (
                <div>
                    <IconButton onClick={() => DownloadProjectFile(params.row.id, 'estimates')}>
                        <DownloadPdfIcon/>
                    </IconButton>

                    <IconButton onClick={() => DownloadProjectFile(params.row.id, 'csv')}>
                        <DownloadCsvIcon/>
                    </IconButton>

                    <IconButton onClick={() => DownloadProjectFile(params.row.id, 'labels')}>
                        <DownloadLabelIcon/>
                    </IconButton>
                </div>)
),
            headerAlign: 'center', align: 'center', width: 160}
    ];

//RETURN STATEMENT------------------------------------------------------------------------------------------------------
    return (
        <div>
            <Box
                style={{backgroundColor: 'rgb(232,235,239)', borderRadius: 25}}
                width={1000}
                alignItems="center"
                mb={5}
                mt={5}
            >
                <h1 style={{color: '#19666c', paddingTop: 20}}>Projects</h1>
                <p
                    align="right"
                    style={{fontSize: '18px', paddingRight: 60, color: "#19666c", marginTop: 15, marginBottom: 0}}
                    className={downloadStatus ? "downloadStatus" : "offscreen"}
                    aria-live="assertive"
                >
                    {downloadStatus}
                </p>
                <CardContent align="center">
                    <DataGrid
                        style={{
                            borderRadius: 15,
                            backgroundColor: 'rgb(255,255,255)',
                            marginRight: 15,
                            marginLeft: 15,
                            marginTop: 0
                        }}
                        columns={Columns}
                        rows={tableLoading ? Array.from({length: 3}, (_, index) => ({id: index})) : tableData}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 5},
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        /*checkboxSelection*/
                    />
                </CardContent>
                <br/> <br/>
                {loading && <CircularProgress/>}
            </ Box>
        </div>
    )
}
export default Dashboard