import { useContext, useState, useEffect, createContext} from "react";
import axios from "../API/axios";
import {useNavigate} from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

const AuthContext = createContext({});

export const AuthProvider = ({children}) => {

    const Register_URL = '/Register';
    const Login_URL = '/auth';
    const tokenValidation_url = '/token'

    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(false)
    const nav = useNavigate()

    useEffect(() => {
        checkUserStatus()
    }, [])

    const loginUser = async (userInfo) =>  {

        setLoading(true)
        try {
            const response= await axios.post(Login_URL,
                userInfo,
                {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
                    withCredentials: false
                }
            );
            if (response.status === 200) {
                const newToken = response.data.access_token;
                localStorage.setItem('placeholderToken', newToken)
                setLoading(false)
                setUser(true)
                nav('/Dashboard')
            }
            console.log(JSON.stringify(response?.data));

        }catch(error){
            setLoading(false)
            return error
        }
    }

    const logoutUser = () => {

        // add logic to log user out and delete token
        localStorage.removeItem('placeholderToken')
        setUser(false)
    }

    const registerUser = async (userInfo) =>  {

        setLoading(true)
        try {
            const response= await axios.post(Register_URL,
                userInfo,
                {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
                    withCredentials: false
                });
            if (response.status === 200) {
                const newToken = response.data.access_token;
                localStorage.setItem('placeholderToken', newToken)
                setLoading(false)
                setUser(true)
                nav('/Dashboard')
            }
            console.log(JSON.stringify(response?.data));

        } catch(error) {
            setLoading(false)
            return error
        }
    }



    const checkUserStatus = async () => {
        const response = await axios.get(tokenValidation_url,
            {headers: {Authorization: 'Bearer '.concat(localStorage.getItem('placeholderToken'))}})
            .then( (response) => {
                if (response.status === 200) {setUser(true)}
            }).catch((error) => {
                console.log(error)
                if (error.response?.status === 401) {
                    logoutUser();
                }
            })
        setLoading(false)

    }

    const contextData = {
        user,
        loginUser,
        logoutUser,
        registerUser,
        checkUserStatus
    }
    return (
        <AuthContext.Provider value = {contextData}>
            {loading ? <CircularProgress sx={{marginTop: 50}} /> : children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {return useContext(AuthContext)}  //  Custom Hook enabling easy access of user & auth info

export default AuthContext
