import * as React from 'react';
import {AppBar, Box, Toolbar, Button, Menu, MenuItem, IconButton} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useAuth} from "../Context/AuthProvider";
import ZippyDocsLogo from "../Logo/SVGIcon";

const pages = ['Dashboard', 'New Project'];
const setting = ['Profile','Account', 'Logout'];

function ResponsiveAppBar() {
    const {userStatus, checkUserStatus} = useAuth();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {user, logoutUser} = useAuth()

    const handlePageClick = (page) => {
        checkUserStatus();
        navigate(`/${page.replace(/\s+/g, '')}`) // Replace space with empty string
    }

    const handleUserMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSettingClick = (setting) => {
        // Handle click on settings (profile, account, logout)
        console.log('Setting clicked:', setting);
        handleUserMenuClose(); // Close the menu after clicking on a setting
        if(setting === 'profile') {
            checkUserStatus();
            navigate('/Profile');
        } else if (setting === 'account') {
            checkUserStatus();
            navigate('/Account');
        } else if (setting === 'logout') {
            logoutUser();
        }
    };

    return (
        <AppBar position="static" sx={{backgroundColor: 'rgba(8,8,35,0.59)'}}>
            <Toolbar>
                {user ? (
                    <>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 'auto'}}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    onClick={() => handlePageClick(page)}
                                    component={Link}
                                    to={`/${page.replace(/\s+/g, '')}`}
                                    color="inherit"
                                    sx={{ mx: 1 }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <IconButton sx={{ color: "#ee5840" }}
                                        onClick={handleUserMenuOpen}>
                                <AccountCircleIcon sx={{ width: 40, height: 40, }}/>
                            </IconButton>

                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleUserMenuClose}

                            >
                                <MenuItem onClick={() => handleSettingClick('profile')}>Profile</MenuItem>
                                <MenuItem onClick={() => handleSettingClick('account')}>Account</MenuItem>
                                <MenuItem onClick={() => handleSettingClick('logout')}>Logout</MenuItem>
                            </Menu>
                        </Box>
                    </>
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <ZippyDocsLogo/>
                    </Box>)}
            </Toolbar>
        </AppBar>
    );
}

export default ResponsiveAppBar;