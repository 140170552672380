// IMPORTS---------------------------------------------------------------------------------------------------------
import * as React from "react";
import { useRef, useState, useEffect, useContext } from 'react';
import AuthContext, {useAuth} from "../Context/AuthProvider";
import {Card, CardContent, TextField, Button, Grid} from '@mui/material';

// DECLARATIONS---------------------------------------------------------------------------------------------------------
const Login = () => {
    const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const {user, loginUser} = useAuth()


    useEffect(() => {
        userRef.current?.focus();
    }, [])
    useEffect(() => {
        setErrMsg('');
    }, [user, password])


    const handleSubmit = async (e) => {
        e.preventDefault();


        try {
            const authData = new FormData();
            authData.append('username', username);
            authData.append('password', password);

            loginUser(authData)

            setAuth({ user, password });
            setUsername('');
            setPassword('');
            setSuccess(true);


        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Incorrect Username or Password');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current?.focus();
        }
    }


//RETURN STATEMENT------------------------------------------------------------------------------------------------------
    return (
        <Card>
            <form onSubmit={handleSubmit}>
                <h1 style={{color: '#19666c', paddingTop: 20, marginBottom: 0}}>Sign In</h1>
                <a style={{color: '#001313'}}>Welcome, please sign in to your account.</a>

                <CardContent sx={{marginTop: 3}}>
                    <Grid container direction="column" spacing={2} alignItems="center">

                        {/* USERNAME */}
                        <Grid item>
                            <TextField
                                InputLabelProps={{required: false}}
                                label="Username"
                                margin="dense"
                                variant="outlined"
                                type="text"
                                id="username"
                                autoComplete="off"
                                onChange={(e) => setUsername(e.target.value)}
                                value={username}
                                required
                            />
                        </Grid>

                        {/* PASSWORD */}
                        <Grid item>
                            <TextField
                                InputLabelProps={{required: false}}
                                label="Password"
                                margin="dense"
                                type="password"
                                id="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                required
                            />
                        </Grid>

                        {/* ERROR HANDLER */}
                        <p ref={errRef} className={errMsg ? "errMsg" : "offscreen"}
                           aria-live="assertive">{errMsg}</p>

                        {/* SUBMIT */}
                        <div style={{width: '100%', maxWidth: '200px'}}>
                            <Button variant="contained" size="large" fullWidth
                                    type={"submit"}>Sign In</Button>
                        </div>

                        <a style={{color: '#100000'}}>Don't have an account?</a>
                        <a style={{
                            color: '#2d5a62',
                            fontWeight: 'bold',
                            textDecoration: 'none'
                        }}
                           href='/Register'>Register</a>
                    </Grid>
                </CardContent>
            </form>
        </Card>
    )
}
export default Login;