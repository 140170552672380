// IMPORTS--------------------------------------------------------------------------------------------------------------
import * as React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {AuthProvider} from "./Context/AuthProvider";
import Login from "./Pages/Login";
import NewProject from "./Pages/NewProject";
import {ThemeProvider} from "@mui/material";
import Box from "@mui/material/Box";
import Dashboard from "./Pages/Dashboard";
import PrivateRoute from "./Context/PrivateRoute";
import Container from "@mui/material/Container";
import {theme} from './Theme'
import ResponsiveAppBar from "./Components/AppBar";
import PublicRoute from "./Context/PublicRoute";
import Profile from "./Pages/Profile";
import Account from "./Pages/Account";
import Register from "./Pages/Register";

// APP-----------------------------------------------------------------------------------------------------------------
export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <Box style={{backgroundColor: '#101328'}}>
                <Container align="center"
                    style={{
                       minHeight: '100vh',
                       backgroundImage: "linear-gradient(to bottom, #2a334f, #22092C)",
                       paddingTop: 30
                    }}>


                    <AuthProvider>
                        <ResponsiveAppBar/>
                        <Routes>
                            <Route path={"/"} element={<Navigate to={"/Dashboard"} replace/>}/>

                            <Route path="/" element={<PublicRoute />}>
                            <Route path="/Login" element={<Login/>}/>
                            <Route path="/Register" element={<Register/>}/>
                            </Route>

                            <Route path="/" element={<PrivateRoute />}>
                                <Route path="/Dashboard" element={<Dashboard/>} />
                                <Route path="/NewProject" element={<NewProject/>} />
                                <Route path="/Profile" element={<Profile/>} />
                                <Route path="/Account" element={<Account/>} />
                            </Route>
                        </Routes>
                    </AuthProvider>
                    </Container>
                </Box>
         </ThemeProvider>
    );
}