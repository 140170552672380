import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useAuth} from "./AuthProvider";

const PublicRoute = ({component: component, ...rest}) => {
    const {user} = useAuth() //localStorage.getItem("placeholderToken");
    return user ? <Navigate to={"/Dashboard"}/> : <Outlet/>;
};

export default PublicRoute;


