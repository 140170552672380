//IMPORTS---------------------------------------------------------------------------------------------------------
import * as React from 'react';
import { useNavigate } from "react-router-dom"
import axios from "../API/axios";
import { useEffect, useRef, useState } from "react";

//Components
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Checkbox from "@mui/material/Checkbox";
import {Button, Card, FormControlLabel, TextField} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import FormGroup from "@mui/material/FormGroup";
import Slider from '@mui/material/Slider';
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";

//Icons, etc.
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TodayIcon from '@mui/icons-material/Today';
import PublicIcon from '@mui/icons-material/Public';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import {useAuth} from "../Context/AuthProvider";

//DECLARATIONS---------------------------------------------------------------------------------------------------------
const NewProjectUrl = '/NewProject';

const NewProject = () => {
    const userRef = useRef();
    const errRef = useRef();


    const {user, logoutUser} = useAuth()
    const [SingleFam, setSingleFam] = useState(true);
    const [MultiFam, setMultiFam] = useState(true);
    const [Commercial, setCommercial] = useState(true);
    const [Seminole, setSeminole] = useState('seminole');
    const [Orange, setOrange] = useState('orange');
    const [Osceola, setOsceola] = useState('osceola');
    const [YearBuiltMin, setYearBuiltMin] = useState(null);
    const [YearBuiltMax, setYearBuiltMax] = useState(null);
    const [YearSoldMin, setYearSoldMin] = useState(null);
    const [YearSoldMax, setYearSoldMax] = useState(null);
    const [SqftRange, setSqftRange] = useState([null, null]);
    const [ProjectName, setProjectName] = useState('');
    const [KML, setKML] = useState(null);
    const [loading, setLoading] = useState(false);

    const [errMsg, setErrMsg] = useState('');

    const clearFile = () => {
        setKML(null);
    };


    useEffect(() => {
        userRef.current?.focus();
    }, [])

    const nav = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {

            const Counties = [Seminole, Orange, Osceola];
            let CountiesStr = Counties.toString();

            const ProjectParameters = new FormData();
            if (YearBuiltMin) {ProjectParameters.append("dateBuiltMin", YearBuiltMin.toISOString().substring(0, 10));}
            if (YearBuiltMax) {ProjectParameters.append("dateBuiltMax", YearBuiltMax.toISOString().substring(0, 10));}
            if (YearSoldMin)  {ProjectParameters.append("dateSoldMin", YearSoldMin.toISOString().substring(0, 10));}
            if (YearSoldMax) {ProjectParameters.append("dateSoldMax", YearSoldMax.toISOString().substring(0, 10));}
            if (SqftRange[1]) {ProjectParameters.append("sqftMax", SqftRange[1]);}
            if (SqftRange[0])  {ProjectParameters.append("sqftMin", SqftRange[0]);}
            ProjectParameters.append("counties", CountiesStr);
            ProjectParameters.append("singleFamily", SingleFam);
            ProjectParameters.append("multiFamily", MultiFam);
            ProjectParameters.append("commercial", Commercial);
            ProjectParameters.append("projectName", ProjectName);
            if (KML != null) {
                ProjectParameters.append("kml", KML);
            }

            const response = await axios.post(NewProjectUrl,
                ProjectParameters,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: 'Bearer '.concat(localStorage.getItem('placeholderToken'))
                    },
                    withCredentials: false
                }
            );
            if (response.status === 200) {
                setLoading(false);
                nav('/Dashboard')
            } else if (response.status === 204) {
                setErrMsg("No parcels found, try different parameters!")
                console.log(errMsg);
                setLoading(false);
            }
            console.log(JSON.stringify(response));
            setLoading(false);

        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
                console.log(errMsg);
                setLoading(false);
            } else if (err.response?.status === 401) {
                logoutUser();
            } else {
                setErrMsg('Search Failed');
                console.log(errMsg);
                setLoading(false);
            }
            errRef.current?.focus();
        }
    }
//RETURN STATEMENT------------------------------------------------------------------------------------------------------
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Card style={{ overflow: 'auto', marginBottom: 20 }}>
                <form onSubmit={handleSubmit}>
                    <h1 style={{color: '#19666c', paddingTop: 20}}>New Project</h1>
                    <TextField
                        onChange={(e) => {
                            setProjectName(e.target.value)
                            setErrMsg("")}}
                        required
                        type="name"
                        id="ProjectName"
                        placeholder="Name Me!"
                    />
                    <CardContent>
                        <Accordion style={{
                            borderTopRightRadius: 15, borderTopLeftRadius: 15
                        }}>
                            <AccordionSummary
                                expandIcon={<ArrowDownwardIcon style={{color: '#862340'}}/>}
                                aria-controls="panel1-content"
                                id="panel1-header">
                                <HomeIcon sx={{marginRight: '10px', color: '#000000'}}/>
                                <Typography>Property Type*</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox onChange={(e) => {
                                                    setSingleFam(e.target.checked)
                                                    setErrMsg("")}}
                                                          defaultChecked={true}
                                                          id='SingleFam'/>}
                                            label="Single Family"/>
                                        <FormControlLabel
                                            control={
                                                <Checkbox onChange={(e) => {
                                                    setMultiFam(e.target.checked)
                                                    setErrMsg("")}}
                                                          defaultChecked={true}
                                                          id='MultiFam'/>}
                                            label='Multi-Family'/>
                                        <FormControlLabel
                                            control={
                                                <Checkbox onChange={(e) => {setCommercial(e.target.checked)
                                                    setErrMsg("")}}
                                                          defaultChecked={true}
                                                          id='Commercial'/>}
                                            label='Commercial'/>
                                    </FormGroup>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ArrowDownwardIcon style={{color: '#862340'}}/>}
                                aria-controls="panel2-content"
                                id="panel2-header">
                                <LocationOnIcon sx={{marginRight: '10px', color: '#000000'}}/>
                                <Typography>County*</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                defaultChecked={true}
                                                onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSeminole('seminole')
                                                } setErrMsg("")
                                                }}
                                                      id='Seminole'/>}
                                        label='Seminole'/>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                defaultChecked={true}
                                                onChange={(e) => {
                                                if (e.target.checked) {
                                                    setOrange('orange')
                                                } setErrMsg("")
                                            }}
                                                      id='Orange'/>}
                                        label='Orange'/>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                defaultChecked={true}
                                                onChange={(e) => {
                                                if (e.target.checked) {
                                                    setOsceola('osceola')
                                                } setErrMsg("")
                                            }}
                                                      id='Osceola'/>}
                                        label='Osceola'/>
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ArrowDownwardIcon style={{color: '#862340'}}/>}
                                aria-controls="panel2-content"
                                id="panel2-header">
                                <TodayIcon sx={{marginRight: '10px', color: '#000000'}}/>
                                <Typography>Year Built</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <Stack>
                                                <DatePicker views={['year']}
                                                            label="Min"
                                                            disableFuture={true}
                                                            onChange={(newValue) => {setYearBuiltMin(newValue)
                                                                setErrMsg("")}}
                                                            id='setYearBuiltMin'
                                                            sx={{marginBottom: 1}}/>
                                                <DatePicker views={['year']}
                                                            label="Max"
                                                            disableFuture={true}
                                                            onChange={(newValue) => {setYearBuiltMax(newValue)
                                                                setErrMsg("")}}
                                                            id='setYearBuiltMax'
                                                            sx={{marginBottom: 1}}/>

                                            </Stack>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ArrowDownwardIcon style={{color: '#862340'}}/>}
                                aria-controls="panel2-content"
                                id="panel2-header">
                                <InsertInvitationIcon sx={{marginRight: '10px', color: '#000000'}}/>
                                <Typography>Year Sold</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <Stack>
                                                <DatePicker views={['year']}
                                                            label="Min"
                                                            disableFuture={true}
                                                            onChange={(newValue) => {setYearSoldMin(newValue)
                                                                setErrMsg("")}}
                                                            id='YearSoldMin'
                                                            sx={{marginBottom: 1}}/>
                                                <DatePicker views={['year']}
                                                            label="Max"
                                                            disableFuture={true}
                                                            onChange={(newValue) => {setYearSoldMax(newValue)
                                                                setErrMsg("")}}
                                                            id='YearSoldMax'
                                                            sx={{marginBottom: 1}}/>
                                            </Stack>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ArrowDownwardIcon style={{color: '#862340'}}/>}
                                aria-controls="panel2-content"
                                id="panel2-header">
                                <SquareFootIcon sx={{marginRight: '10px', color: '#000000'}}/>
                                <Typography>Sq Ft</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <Slider
                                        defaultValue={[1000, 5000]}
                                        sx={{color: "#bd3143", maxWidth: 300}}
                                        valueLabelDisplay="auto"
                                        min={1000}
                                        max={5000}
                                        onChange={(e) => {setSqftRange(e.target.value)
                                            setErrMsg("")}}
                                        id='SqftRange'/>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion style={{borderBottomRightRadius: 15, borderBottomLeftRadius: 15}}>
                            <AccordionSummary
                                expandIcon={<ArrowDownwardIcon style={{color: '#862340'}}/>}
                                aria-controls="panel2-content"
                                id="panel2-header">
                                <PublicIcon sx={{marginRight: '10px', color: '#000000'}}/>
                                <Typography>Upload KML</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <input
                                    accept=".kml, application/vnd.google-earth.kml+xml" // Accept only KML files
                                    style={{display: 'none'}}
                                    id="file-upload-input"
                                    type="file"
                                    onChange={(e) => {setKML(e.target.files[0])
                                        setErrMsg("")}}
                                />
                                <label htmlFor="file-upload-input">
                                    <Button
                                        component="span"
                                        sx={{
                                            color: '#0f3639',
                                            marginBottom: '0',
                                            backgroundColor: '#e5e7ea',
                                            border: '1.75px solid',
                                            borderColor: '#bfc1c7',
                                            '&:hover': {
                                                backgroundColor: '#cbcbd4', // Change background color on hover
                                                color: '#040f10', // Change text color on hover
                                            },
                                        }}
                                    > Upload File
                                    </Button>
                                </label>
                                {KML && (
                                    <div>
                                        <Container sx={{
                                            backgroundColor: 'transparent',
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: '45px'
                                        }}>
                                            <p>{KML.name}</p>
                                            <IconButton onClick={() => { clearFile(); setErrMsg(""); }}>
                                                <ClearIcon/>
                                            </IconButton>
                                        </Container>
                                    </div>
                                )}
                            </AccordionDetails>
                        </Accordion>

                        <br/>
                        <p
                            align="center"
                            style={{
                                fontSize: '18px',
                                color: "#85233f",
                                fontWeight: 'bold'
                            }}
                            className={errMsg ? "creationStatus" : "offscreen"}
                            aria-live="assertive"
                        >
                            {errMsg}
                        </p>
                        <Button variant="contained" type={"submit"}
                                size="large"
                                disabled={loading}>
                            Generate
                        </Button>
                    </CardContent>
                </form>
            </Card>
            {loading && (
                <div style={{textAlign: 'center', marginTop: 0, marginBottom: 20}}>
                    <CircularProgress/>
                </div>
            )}
        </div>
    );
}


export default NewProject;