// IMPORTS---------------------------------------------------------------------------------------------------------
import * as React from "react";
import { useRef, useState, useEffect, useContext } from 'react';
import AuthContext, {useAuth} from "../Context/AuthProvider";
import {Card, CardContent, TextField, Button, Grid} from '@mui/material';

// DECLARATIONS / SET UP---------------------------------------------------------------------------------------------------------
const Register = () => {
    const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();
    const [newUser, setNewUser] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const {user, registerUser} = useAuth()

    useEffect(() => {
        userRef.current?.focus();
    }, [])
    useEffect(() => {
        setErrMsg('');
    }, [newUser, newPassword, newEmail, confirmPassword])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setPasswordError(true);
        } else {
            setPasswordError(false);

            try {
                const authData = new FormData();
                authData.append('username', newUser);
                authData.append('password', newPassword);
                authData.append('email', newEmail);

                registerUser(authData);

                setAuth({newUser, newPassword, newEmail});
                setNewUser('');
                setNewPassword('');
                setNewEmail('');
                setSuccess(true);

            } catch (err) {
                if (!err?.response) {
                    setErrMsg('No Server Response');
                } else if (err.response?.status === 400) {
                    setErrMsg('Missing a field');
                } else {
                    setErrMsg('Sign Up Failed');
                }
                errRef.current?.focus();
            }
        }
        }

//RETURN STATEMENT------------------------------------------------------------------------------------------------------
    return (
        <Card>
            <form autoComplete={"off"}
                  onSubmit={handleSubmit}>
                <h1 style={{color: '#19666c', paddingTop: 20, marginBottom: 0}}>Register</h1>
                <a style={{color: '#001313'}}>Please enter your information.</a>

                <CardContent sx={{marginTop: 3}}>
                    <Grid container direction="column" spacing={2} alignItems="center">

                        {/* EMAIL */}
                        <Grid item>
                            <TextField
                                InputLabelProps={{required: false}}
                                label="Email"
                                margin="dense"
                                variant="outlined"
                                type="email"
                                id="email"
                                autoComplete="off"
                                onChange={(e) => setNewEmail(e.target.value)}
                                value={newEmail}
                                required
                            />
                        </Grid>

                        {/* USERNAME */}
                        <Grid item>
                            <TextField
                                InputLabelProps={{required: false}}
                                label="Username"
                                margin="dense"
                                variant="outlined"
                                type="text"
                                id="username"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setNewUser(e.target.value)}
                                value={newUser}
                                required
                            />
                        </Grid>

                        {/* PASSWORD */}
                        <Grid item>
                            <TextField
                                InputLabelProps={{required: false}}
                                label="Password"
                                margin="dense"
                                type="password"
                                id="password"
                                autoComplete="off"
                                onChange={(e) => setNewPassword(e.target.value)}
                                value={newPassword}
                                required
                            />
                        </Grid>

                        {/* CONFIRM PASSWORD */}
                        <Grid item>
                            <TextField
                                InputLabelProps={{required: false}}
                                label="Confirm Password"
                                margin="dense"
                                type="password"
                                id="password"
                                error={passwordError}
                                helperText={passwordError ? "Passwords don't match" : ""}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                value={confirmPassword}
                                required
                            />
                        </Grid>


                        {/* ERROR HANDLER */}
                        <p ref={errRef} className={errMsg ? "errMsg" : "offscreen"}
                           aria-live="assertive">{errMsg}</p>
                        <br/>

                        {/* SUBMIT */}
                        <div style={{width: '100%', maxWidth: '200px'}}>
                            <Button variant="contained" size="large" fullWidth
                                    type={"submit"}>Sign Up</Button>
                        </div>
                        <br/><br/>
                        <a style={{color: '#100000'}}>Already have an account?</a>
                        <br/>
                        <a style={{
                            color: '#0a3d42',
                            fontWeight: 'bold',
                            textDecoration: 'none'
                        }}
                           href='/Login'>Sign In</a>
                    </Grid>
                </CardContent>
            </form>
        </Card>
    )
}
export default Register;